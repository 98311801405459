import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import { Breadcrum, SEO } from "../../components/index"
import { faqsType } from '../../types'

class FaqPage extends React.PureComponent {
  breadcrumPages = [
    {
      text: 'FAQ',
      to: '/faq/',
      active: true
    }
  ];

  render () {
    const title = "Frequently Asked Token Questions (FAQ)";
    const { edges } = this.props.data.faqPage;
    return (
      <>
        <SEO
          title={title}
          description="Everything you've ever want to ask about Magic: the Gathering (MTG) token cards. Frequently Asked Questions (FAQ) all about MTG Token cards."
          keywords={['faq', 'Frequently Asked Questions', 'facts']}
          pageUrl='/faq/'
        />
        <Breadcrum pages={this.breadcrumPages} />
        <h1>{title}</h1>
        <p>Questions about Magic: the Gathering token cards that we are Frequently asked. If you have a question you'd like answering contact us via <a href="https://www.twitter.com/codefor">Twitter</a>.</p>
          <ul>
          {
            edges.map((q, i)=>{
              const { question, route } = q.node;
              return (
                <li key={i}>
                  <Link to={route}>
                    {question}
                  </Link>
                </li>
              )
            })
          }
          </ul>
      </>
    )
  }
}

FaqPage.propTypes = {
  data: PropTypes.shape({
    faqPage: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(faqsType)
        })
      ).isRequired
    }).isRequired
  }).isRequired
}


export const faqPageQuery = graphql`
  query FaqPageQuery {
    faqPage: allFaqJson {
      edges {
        node {
          route
          question
          answer
        }
      }
    }
  }
`

export default FaqPage
